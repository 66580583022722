







































































import Vue from 'vue';
import newAdd from './newAdd.vue';
import moveClassify from './moveClassify.vue';
import deleteClassify from './deleteClassify.vue';

import { MajorType } from '@/interface/costQuotaLibrary';
import {
  updateMajorName,
  queryEnterpriseMajor,
  getFirstDefMajor,
} from '@/api/costQuotaLibrary/projectMajor';

import { checkUserIdentity } from '../index';
import { mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';

export default Vue.extend({
  name: 'projectTree',
  components: { newAdd, moveClassify, deleteClassify },
  props: {},
  computed: {
    ...mapGetters(['userInfo', 'portalState']),
  },
  data() {
    return {
      treeData: [] as MajorType[],
      originalTreeData: [] as MajorType[],
      replaceFields: {
        key: 'sequenceNbr',
        title: 'promajorName',
        children: 'majorList',
      },
      addVisible: false,
      moveVisible: false,
      deleteVisible: false,
      classifyInfo: null,
      expandedKeys: [] as string[],
      selectedKeys: [] as string[],
    };
  },
  watch: {
    'userInfo.agencyCode': function (val, oldVal) {
      if (val && val !== oldVal) {
        this.getTreeData();
      }
    },
  },
  created() {
    if (this.userInfo) {
      this.getTreeData();
    }
  },
  methods: {
    ...mapMutations(['SET_CURRENTMAJORINFO']),
    addOrMoveChange(attr: 'addVisible' | 'moveVisible') {
      if (!checkUserIdentity(this)) return;
      this[attr] = true;
    },
    isUngrouped(pos) {
      const arr = pos.split('-');
      if (arr.length < 3) return false;
      const parentItem = this.treeData[arr[1]];
      const item = parentItem.majorList[arr[2]];
      return (
        parentItem.promajorName === '未分组' && item.promajorName === '未分组'
      );
    },
    /**
     * 修改的值是否改变
     */
    isEditChange(promajorName, pos) {
      const arr = pos.split('-');
      if (arr.length < 3) return false;
      const originalData = this.originalTreeData[arr[1]].majorList[arr[2]];
      return originalData.promajorName !== promajorName;
    },
    edit(dataRef, e) {
      if (!checkUserIdentity(this)) return;
      this.$set(dataRef, 'isDisabled', true);
      this.$nextTick(() => {
        function getParent(c, t): HTMLElement {
          while (c.nodeName.toLowerCase() != t) {
            c = c.parentNode;
          }
          return c;
        }
        getParent(e.target, 'span').querySelector('input')?.focus();
      });
    },
    deleteHandler(dataRef) {
      if (!checkUserIdentity(this)) return;
      this.classifyInfo = dataRef;
      this.deleteVisible = true;
    },
    editBlur(dataRef, pos) {
      this.$set(dataRef, 'isDisabled', false);
      if (!this.isEditChange(dataRef.promajorName, pos)) {
        return;
      }
      if (dataRef.promajorName.trim() === '') {
        this.getTreeData();
        return this.$message.warning('名称不能为空');
      }
      updateMajorName({
        newName: dataRef.promajorName,
        sequencenbr: dataRef.sequenceNbr,
        agencycode: this.userInfo?.agencyCode,
        parentid: dataRef.parentCode,
      }).then(({ status, result }) => {
        this.getTreeData();
        if (status === 200 && result === '修改成功') {
          return this.$message.success(result);
        }
        return this.$message.error(result);
      });
    },
    getTreeData() {
      let { agencyCode, userType } = this.userInfo;
      if (userType !== 'Enterprise') {
        agencyCode = 'DefxiliAgencyCode';
      } else {
        agencyCode = this.portalState.agencyCode;
      }
      queryEnterpriseMajor(agencyCode).then(({ status, result }) => {
        if (status !== 200) return;
        this.treeData = result.majorList;
        this.originalTreeData = _.cloneDeep(result.majorList);
        this.getFirstDefMajor();
      });
    },
    getFirstDefMajor() {
      let { agencyCode, userType } = this.userInfo;
      if (userType !== 'Enterprise') {
        agencyCode = 'DefxiliAgencyCode';
      } else {
        agencyCode = this.portalState.agencyCode;
      }
      getFirstDefMajor(agencyCode).then((res) => {
        if (res.status === 200 && res.result) {
          this.treeData.forEach((item) => {
            item.majorList.forEach((child) => {
              if (child.sequenceNbr === res.result) {
                this.expandedKeys.push(item.sequenceNbr);
                this.SET_CURRENTMAJORINFO(child);
              }
            });
          });
          this.expandedKeys.push(res.result as string);
          this.selectedKeys = [res.result as string];
        } else {
          const firstData = this.treeData[0];
          const sequenceNbr = firstData.majorList[0].sequenceNbr;
          this.expandedKeys.push(firstData.sequenceNbr as string);
          this.SET_CURRENTMAJORINFO(firstData.majorList[0]);
          this.expandedKeys.push(sequenceNbr as string);
          this.selectedKeys = [sequenceNbr];
        }
      });
    },
    selectNode(
      selectedKeys: string[],
      {
        node,
      }: {
        node: { dataRef: Record<string, string | number>; expanded: boolean };
      }
    ) {
      const data = node.dataRef;
      if (data.majortype === 2) {
        this.SET_CURRENTMAJORINFO(node.dataRef);
      }
      const nodeKey = node.dataRef.sequenceNbr as string;
      if (!node.expanded) {
        this.expandedKeys.push(nodeKey);
      } else {
        this.expandedKeys = this.expandedKeys.filter((key: string) => {
          return key !== nodeKey;
        });
      }
    },
  },
});
