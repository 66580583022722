








































import { qyStlBillDe } from '@/api/costQuotaLibrary/costLibrary';
import { deleteMajor } from '@/api/costQuotaLibrary/projectMajor';
import Vue from 'vue';
import moveClassify from './moveClassify.vue';
export default Vue.extend({
  name: 'deleteClassify',
  components: { moveClassify },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    classifyInfo: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    majorNbr(): string {
      return this.classifyInfo?.sequenceNbr;
    },
  },
  watch: {
    visible: function (val, oldVal) {
      if (val && !oldVal) {
        this.getStlBillQuotaTotal();
      }
    },
  },
  data() {
    return {
      moveVisible: false,
      stlBillQuotaTotal: 0,
    };
  },
  methods: {
    cancel() {
      this.$emit('update:visible', false);
    },
    move() {
      this.cancel();
      this.moveVisible = true;
    },
    async getStlBillQuotaTotal() {
      const { status, result } = await qyStlBillDe({
        page: 1,
        limit: 1,
        majorNbr: this.majorNbr,
      });
      if (status === 200 && result) {
        this.stlBillQuotaTotal = result.total;
      }
    },
    deleteHandler() {
      deleteMajor(this.majorNbr).then(({ status, result }) => {
        if (status === 200 && result) {
          this.$emit('callback');
          this.cancel();
          return this.$message.success(result);
        }
        return this.$message.error(result);
      });
    },
  },
});
